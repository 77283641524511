import React from 'react';

import PageStatic from '../../components/page-static';
import StartProjectForm from '../../components/contact/start-project-form';

function StartProjectPage(props) {
  return (
    <PageStatic seoTitle={'Contact us. Start A Project'} {...props} noCtaBlock>
      <StartProjectForm />
    </PageStatic>
  );
}

export default StartProjectPage;
